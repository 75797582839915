import type { AxiosError } from 'axios';

import AlertStore from 'jsx/stores/alertStore';
import Config from 'jsx/constants/config';
import httpStatusCodes from 'jsx/constants/httpStatusCodes';

import { isErrorResponse, isValidationErrorResponse } from '../errors';
import { AxiosBuilderInterceptor } from './types';

const shouldSuppressAlert = (error: AxiosError<unknown>): boolean => {
  if (
    error.response?.status === httpStatusCodes.locked ||
    error.response?.status === httpStatusCodes.forbidden ||
    error.response?.status === httpStatusCodes.tooManyAttempts
  ) {
    return true;
  }
  return false;
};

const handleAxiosError = (error: AxiosError<unknown>): void => {
  if (shouldSuppressAlert(error)) return;

  if (!error.response || !isErrorResponse(error.response.data)) {
    AlertStore.add(
      error.message === 'Network Error'
        ? Config.errorMessages.network
        : Config.errorMessages.generic,
    );
    return;
  }

  const { data } = error.response;

  if (!isValidationErrorResponse(data)) {
    AlertStore.add(data.message);
    return;
  }

  Object.values(data.errors)
    .flat()
    .forEach((message) => AlertStore.add(message));
};

export const errorHandler = (): AxiosBuilderInterceptor => ({
  type: 'response',
  errorHandler: (error: AxiosError) => {
    handleAxiosError(error);

    return Promise.reject(error);
  },
});
