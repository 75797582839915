import throwOnUndefinedProperty from 'jsx/utils/throwOnUndefinedProperty';

const HTTP_STATUS_CODES = {
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  locked: 423,
  tooManyAttempts: 429,
  internalServerError: 500,
  serviceUnavailable: 503,
} as const;

export type HTTP_STATUS_CODE = valueof<typeof HTTP_STATUS_CODES>;

export default throwOnUndefinedProperty(HTTP_STATUS_CODES);
