import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import {
  AxiosBuilderInterceptor,
  csrfHandler,
  errorHandler,
  authErrorHandler,
} from './interceptors';

export const defaultInterceptors = () => [
  // Order is important - we don't want to flash a message just before redirecting,
  // so add the authErrorHandler interceptor before the errorHandler interceptor.
  authErrorHandler,
  errorHandler,
  csrfHandler,
];

export const defaultConfig = () => ({
  withCredentials: false,
});

/**
 * Construct a Axios instance with our required config and interceptors.
 */
export const buildAxiosInstance = (
  interceptors?: Array<(axios: AxiosInstance) => AxiosBuilderInterceptor>,
  config?: AxiosRequestConfig,
): AxiosInstance => {
  const instance = axios.create(config ?? defaultConfig());

  (interceptors ?? defaultInterceptors()).forEach((create) => {
    const interceptor = create(instance);

    if (interceptor.type === 'request') {
      instance.interceptors.request.use(
        interceptor.handler,
        interceptor.errorHandler ?? Promise.reject,
      );
    } else {
      instance.interceptors.response.use(
        interceptor.handler,
        interceptor.errorHandler ?? Promise.reject,
      );
    }
  });

  return instance;
};
