import { wrapUrls } from 'jsx/utils/urlWrapper';
import { REQUEST_BRAND } from 'jsx/utils/brand';
import { encoder } from 'jsx/utils/queryParams';

import CONFIG from './config';
import { PurchaseContext } from '../api/generated';
import { EstimateOptions } from '../stores/pricingStore';

const API_URLS = {
  cancelSubscription: 'api.admin.patient.subscription.delete',
  cancelReasons: 'app.patient.payment.cancelReasons',
  checkMessages: 'api.patient.checkMessages',
  cohorts: 'api.patient.cohorts',
  createEcommerceCart: 'api.ecommerce.cart.store',
  experimentCohort: 'api.experiments.cohort',
  featureUserContext: 'api.feature.user-context',
  featureFlags: 'api.featureFlags',
  flashMessages: 'api.flashMessages',
  itemsPaymentDetails: 'app.patient.payment.itemsPaymentDetails',
  liveConsultations: 'api.patient.liveConsultations',
  liveConsultationAvailableTimeSlots:
    'api.patient.liveConsultations.availableTimeSlots',
  liveConsultationCancel: 'api.patient.liveConsultations.entity.cancel',
  liveConsultationScheduled: 'api.patient.liveConsultations.scheduled',
  magicScenarios: 'api.magic.scenarios',
  medicalInfo: 'api.patient.medicalInfo',
  messages: 'api.patient.messages',
  messagesMarkRead: 'api.patient.messages.markRead',
  orders: 'api.ecommerce.orders',
  patientProvider: 'api.patient.providers.self',
  phoneNumber: 'app.users.self.phoneNumber',
  photo: 'api.patient.photos.entity.arguments',
  photos: 'api.patient.photos',
  photoEntity: 'api.patient.photos.entity',
  photoUpload: 'api.patient.photos.upload',
  pills: 'api.patient.pills',
  productSelectionGates: 'api.productSelectionGates',
  promotions: 'api.promotions',
  referrerUtms: 'api.captureReferrer',
  referral: 'api.patient.getReferral',
  removePatientProductDeferrals: 'app.patient.remove-patient-product-deferrals',
  usedReferral: 'api.patient.usedReferral',
  shipments: 'api.patient.shipments',
  shipmentHolidays: 'app.api.holidays',
  shipmentTracker: 'api.patient.shipments.tracker',
  shortUtms: 'api.shortUtms',
  subscriptionItemOptions: 'api.patient.subscriptionItems.options',
  subscriptionProducts: 'api.patient.subscriptionProducts.index',
  subscriptions: 'api.patient.subscriptions',
  updateSubscriptionItems: 'app.patient.subscriptions.updateItems',
  user: 'api.patient',
  userPills: 'api.patient.userPills',
  vipInfluencer: 'api.guest.invites.show',
  winbackPromotions: 'api.promotions.winback',
  csrf: 'api.auth.csrf',
  logout: 'logout',
  hugPack: 'api.hugPack',
} as const;

const SURVEY_V2_API_URLS = {
  index: 'api.surveyV2.index',
  show: 'api.surveyV2.show',
  store: 'api.surveyV2.store',
  photos: 'api.surveyV2.photos',
  responses: 'api.surveyV2.responses',
} as const;

const PRODUCT_OPT_IN_API_URLS = {
  show: 'api.productOptIns.show',
  create: 'api.productOptIns.create',
} as const;

const BRAND_TRANSFER_API_URLS = {
  index: 'api.patient.brandTransfer',
  saveBrandTransfer: 'api.patient.brandTransfer.saveBrandTransfer',
} as const;

const OFFER_URLS = {
  show: 'api.offers.show',
};

export const SUPPORT_URLS = {
  support: (endpoint = '', brand = REQUEST_BRAND) =>
    `https://support.${brand.domain}/${endpoint}`,
  supportEmail: `hello@${REQUEST_BRAND.domain}`,
  billingSupport: CONFIG.isCurologyApp
    ? `https://support.curology.com/category/57-billing`
    : 'https://support.withagency.com/category/146-billing',
  moneyBackGuarantee: CONFIG.isCurologyApp
    ? `https://support.curology.com/en_us/90-day-money-back-guarantee-B1fDXnOhO`
    : `https://support.withagency.com/en_us/90-day-money-back-guarantee-ry4WNhOnd`,
} as const;

const URLS = {
  address: '/app/patient/address',
  buyShipment: '/app/shipments/buyNow',
  reorderShipmentRx: '/app/shipments/reorder-rx',
  cancelReasons: '/app/cancel-types',
  community: '/dashboard/community',
  forgotPassword: '/auth/forgot-password',
  invite: '/app/patient/invite',
  landingPage: CONFIG.gatsbyUrl,
  loginDetails: '/api/patient/login-details',
  logout: '/logout',
  messages: '/app/messages',
  messagesMarkRead: '/app/messages/mark-read',
  messageSupport: '/app/messages/support',
  orders: 'api/ecommerce/orders',
  patientProvider: '/app/providers/self',
  pause: '/app/shipments/pause',
  payment: '/app/patient/payment',
  postcardFlags: '/api/postcard-flags',
  postcardLikes: '/api/postcard-likes',
  postcards: '/api/postcards',
  providers: '/app/providers',
  rescheduleShipment: '/app/shipments/reschedule',
  resetPassword: '/auth/reset-password',
  responses: '/app/responses',
  sections: '/app/quiz/sections',
  shipmentHolidays: '/app/api/holidays',
  shipments: '/app/shipments',
  shipmentTracker: '/app/patient/tracker',
  smsMessage: '/api/patient/sms-message',
  smsPhoto: '/sharing/post',
  submitResponses: '/app/quiz/question/submit-responses',
  submittedResponses: '/app/quiz/submitted-responses/',
  guardian: '/api/me/guardian',

  // Photos
  firstPhoto: '/app/photos/first',
  photoFrames: '/api/photo-frames',
  photosForProvider: '/app/photos/for-provider',
  uploadPhotos: '/app/photo/upload',

  // Admin
  adminCancelTypes: '/api/admin/cancel-types',
  adminLogin: '/admin/login',

  // Ineligible minor redirects
  redirectAgencyMinorToCurologySUF:
    'https://app.curology.com/sign-up/get-started/skin/welcome',
  redirectMinorToShop: 'https://curology.com/shop',

  // Marketing/Product
  legal: () => {
    const { gatsbyUrl } = CONFIG;
    return {
      disclaimer: `${gatsbyUrl}/disclaimer`,
      doNotSell: `${gatsbyUrl}/donotsell`,
      guardianTelehealthConsent: `${gatsbyUrl}/guardian-telehealth-consent`,
      privacy: `${gatsbyUrl}/privacy`,
      telehealthConsent: `${gatsbyUrl}/telehealth-consent`,
      terms: `${gatsbyUrl}/terms`,
    };
  },
  guides: '/guides',
  challengeFAQ: 'https://support.curology.com/article/99-21-day-challenge',
  referralTerms: 'https://curology.com/rewardprogram/',
  referralUrgencyTerms: 'https://curology.com/invite5friends/',
  hairFormulaInformation: 'http://www.curology.com/products/hair-formula',
  firstMonthsWithHairFormula:
    'https://curology.com/blog/first-months-with-curology-hair-formula-rx',

  // API
  api: {
    pricingEstimate: ({
      itemIds,
      purchaseContext,
      patientId,
      options,
    }: {
      itemIds: number[];
      purchaseContext: PurchaseContext;
      patientId: number | undefined;
      options: EstimateOptions;
    }) => {
      const queryParams = encoder.encode({
        itemIds,
        purchaseContext,
        patientId,
        ...options,
      });
      return `/api/pricing/estimate?${queryParams}`;
    },
    ...wrapUrls(API_URLS),
    offers: wrapUrls(OFFER_URLS),
    surveyV2: wrapUrls(SURVEY_V2_API_URLS),
    productOptIns: wrapUrls(PRODUCT_OPT_IN_API_URLS),
    brandTransfer: wrapUrls(BRAND_TRANSFER_API_URLS),
  } as const,

  // Support
  ...SUPPORT_URLS,
} as const;

export default URLS;
