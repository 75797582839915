import { isDefined } from '../isDefined';

export interface EncoderOptions {
  /**
   * The maximum depth to recurse into nested arrays and objects while encoding.
   * Arrays and objects below this depth will cause an error to be thrown.
   */
  maxDepth?: number;
}

const defaultOptions: Required<EncoderOptions> = {
  maxDepth: 5,
};

/**
 * Encodes query parameters using the default encoding used by PHP.
 *
 * Keys and values are URI encoded using encodeURIComponent. Nested objects
 * and arrays are supposed up to the `maxDepth` specified in the options. Any
 * additional nesting will cause an error.
 *
 * @see https://www.php.net/manual/en/function.http-build-query.php
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
 *
 *
 * ```
 * encoder.encode({
 *   a: true,
 *   b: 'xyz',
 *   c: [1,2],
 *   d: {
 *    a: 4,
 *    b: 5,
 *   },
 * }); // 'a=1&b=xyz&c[]=1&c[]=2&d[a]=4&d[b]=5'
 * ```
 */
export class QueryParamsEncoder {
  /**
   * The current encoder depth.
   */
  depth = 1;

  /**
   * Options passed to the encoder.
   */
  options: Required<EncoderOptions>;

  constructor(options: EncoderOptions = {}) {
    this.options = {
      ...defaultOptions,
      ...options,
    };
  }

  get maxDepth(): number {
    return this.options.maxDepth;
  }

  /**
   * Encode a value as a query string.
   */
  encode(query: unknown): string {
    if (typeof query !== 'object') {
      return this.encodeValue(query);
    }

    this.resetDepth();

    return this.encodeRecord(query).join('&');
  }

  encodeRecord(query: {} | null, keyPrefix = '') {
    return Object.entries(query ?? {})
      .flatMap(([key, value]): string | string[] | null => {
        if (!isDefined(value)) {
          return null;
        }

        // key[]=1&key[]=2
        if (Array.isArray(value)) {
          return value
            .filter(isDefined)
            .map(
              (val) =>
                `${this.encodeKey(key, keyPrefix)}[]=${this.encodeValue(val)}`,
            );
        }

        // key[prop]=1&key[otherProp]=2
        if (typeof value === 'object') {
          this.increaseDepth();

          return this.encodeRecord(value, this.encodeKey(key, keyPrefix));
        }

        // key=1
        return `${this.encodeKey(key, keyPrefix)}=${this.encodeValue(value)}`;
      })
      .filter(isDefined);
  }

  /**
   * encode a query key to a string.
   */
  encodeKey(key: string, prefix = ''): string {
    const encodedKey = encodeURIComponent(key);

    return prefix ? `${prefix}[${encodedKey}]` : encodedKey;
  }

  /**
   * Encode a query value to a string.
   */
  encodeValue(value: unknown): string {
    if (typeof value === 'boolean') {
      return value ? '1' : '0';
    }

    return encodeURIComponent(String(value));
  }

  resetDepth(): void {
    this.depth = 1;
  }

  increaseDepth(): void {
    this.depth += 1;

    if (this.depth > this.maxDepth) {
      throw new Error(`Maximum encoding depth of ${this.maxDepth} exceeded`);
    }
  }
}

/**
 * Export a default singleton instance of the encoder.
 */
export const encoder = new QueryParamsEncoder();
