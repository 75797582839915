import React from 'react';
import { ThemeProvider } from '@emotion/react';
import {
  primaryTheme,
  secondaryTheme,
  ThemeType,
} from 'radiance-ui/lib/constants';

import CONFIG from 'jsx/constants/config';

interface RadianceThemeProviderProps {
  children: React.ReactNode;
  theme?: ThemeType;
}

const getThemeByDomain = () => {
  if (CONFIG.isCurologyApp || !CONFIG.isAgencyApp) {
    return primaryTheme;
  }

  return secondaryTheme;
};

export const RadianceThemeProvider = ({
  children,
  theme: themeArg,
}: RadianceThemeProviderProps) => {
  const theme = themeArg ?? getThemeByDomain();

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
