import React from 'react';

export default (
  <svg
    css={{ width: '24px' }}
    width="48"
    height="53"
    viewBox="0 0 48 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Curology</title>
    <path
      d="M20.6396 2.60172C24.065 3.46893 25.4092 5.89712 26.3631 7.24129C28.5312 10.3199 29.4417 14.0922 32.3035 18.2548C35.0352 22.2006 42.7534 22.1573 45.3116 17.9947C48.9106 12.1844 45.4417 4.63966 34.7751 1.12747C24.7154 -2.16792 15.6965 2.90525 15.6965 2.90525C17.3008 2.34156 18.9919 2.21148 20.6396 2.60172Z"
      fill="currentColor"
    />
    <path
      d="M42.4499 45.8755C28.3144 45.9622 17.1707 22.8078 12.0109 11.8809C11.0136 9.71292 8.4553 8.71563 6.28727 9.71292C5.50679 10.0598 4.85638 10.6235 4.42278 11.3173C-3.68563 23.5449 -0.346872 39.9785 11.8808 48.0435C16.2168 50.9053 21.3333 52.4663 26.5366 52.4663C26.7968 52.4663 27.0569 52.423 27.3604 52.423C39.7182 52.5097 48.0434 43.7942 48.0434 43.7942C46.439 45.0517 44.4878 45.7888 42.4499 45.8755Z"
      fill="currentColor"
    />
  </svg>
);
