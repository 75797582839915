import { makeObservable, observable, action } from 'mobx';
import moment from 'moment';

import { Constructor } from 'jsx/mixins/types';

export interface InitializableType {
  hasInitialized: boolean;
  initialize: () => Promise<void>;
  initializedAt: moment.Moment | null | undefined;
  isDataStale: boolean;
  shouldInitialize: boolean;
}

const Initializable = <OnInitializeArguments extends unknown[]>(
  superclass: Constructor,
) => {
  abstract class InitializableClass extends superclass {
    @observable
    initializedAt: moment.Moment | null | undefined = null;

    constructor(...args: any[]) {
      super(...args);
      makeObservable(this);
    }

    protected abstract onInitialize(
      ...args: OnInitializeArguments
    ): Promise<unknown>;

    get isDataStale() {
      const hourAgo = moment().subtract(1, 'hours');

      return !!this.initializedAt && this.initializedAt.isBefore(hourAgo);
    }

    get hasInitialized() {
      return !!this.initializedAt;
    }

    get shouldInitialize() {
      return this.hasInitialized && !this.isDataStale;
    }

    @action
    initialize(...props: OnInitializeArguments) {
      if (this.shouldInitialize) {
        return Promise.resolve();
      }

      const init = this.onInitialize(...props);

      // check to see if onInitialize returned a promise
      /* eslint-disable-next-line eqeqeq */
      if (Promise.resolve(init) == init) {
        return init.then(() => {
          this.initializedAt = moment();
        });
      }

      this.initializedAt = moment();
      return Promise.resolve();
    }
  }

  return InitializableClass;
};

export default Initializable;
