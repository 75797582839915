import axios, { AxiosError } from 'axios';

export interface ErrorResponse {
  /**
   * A user facing message describing the error.
   */
  message: string;
}

export interface ValidationErrorResponse extends ErrorResponse {
  /**
   * The validation errors that apply to the request.
   *
   * The Record key is the path to the property that caused the error, in
   * JavaScript dot notation.
   *
   * The Record value is an array of error messages, that may have been localized
   * and are suitable for display to the end user.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Property_accessors#dot_notation
   */
  errors: Record<string, string[]>;
}

export const isErrorResponse = (response: unknown): response is ErrorResponse =>
  typeof (response as ValidationErrorResponse).message === 'string';

export const isValidationErrorResponse = (
  response: unknown,
): response is ValidationErrorResponse =>
  isErrorResponse(response) &&
  Object.prototype.hasOwnProperty.call(response, 'errors');

interface ErrorType {
  errors: Record<string, unknown>;
  [key: string]: unknown;
}

export const isAxiosError = (err: unknown): err is AxiosError<ErrorType> =>
  axios.isAxiosError(err);
