import { wrapUrls } from 'jsx/utils/urlWrapper';
import { SUPPORT_URLS } from 'jsx/constants/urls';

const API_URLS = {
  authenticationInformation: 'api.auth.information',
  emailEligibility: 'api.auth.eligibility.email',
  login: 'api.auth.login',
  register: 'api.auth.register',
  registerOtc: 'api.auth.register.store',
  resetPassword: 'api.auth.resetPassword',
  sendPasswordReset: 'api.auth.forgotPassword',
  sendMagicLink: 'login.magic',
  useMagicLink: 'login.magic.callback',
  verifyEmail: 'email.verify',
} as const;

const REGISTRATION_ELIGIBILITY_API = {
  show: 'api.eligibility.registration.show',
  update: 'api.eligibility.registration.update',
  store: 'api.eligibility.registration.store',
  delete: 'api.eligibility.registration.delete',
  evaluate: 'api.eligibility.evaluate.registration',
  storeFunnelBrandTransfer: 'api.patient.brand.transfer.store',
  showFunnelBrandTransfer: 'api.patient.brand.transfer.show',
} as const;

export default {
  login: '/auth/login',
  loginEmail: '/auth/login/email',
  loginEmailSent: '/auth/login/email/sent',
  loginExpired: '/auth/login/:method/expired',
  welcome: '/auth/welcome',
  dateOfBirth: '/auth/welcome/dateofbirth',
  register: '/auth/welcome/register',
  registerOtc: '/auth/register-otc',
  notEligible: '/auth/welcome/availability-notice',
  emailSaved: '/auth/welcome/email-saved',
  deletingOldAccount: '/auth/welcome/deleting-old-account',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',
  get20: '/get20',
  funnelBrandTransfer: '/auth/brand-transfer',
  funnelBrandTransferHair: '/auth/brand-transfer/hair',
  niceToSeeYouAgain: '/auth/nice-to-see-you-again',
  confirmEligibility: '/auth/welcome/eligibility',

  // other apps
  dashboard: '/dashboard/home',
  signup: '/signup/eligibility/state',
  quiz: '/sign-up/get-started/skin/quiz',
  getStarted: '/sign-up/get-started/skin/eligible',
  ...SUPPORT_URLS,

  api: {
    ...wrapUrls(API_URLS),
    registrationEligibility: wrapUrls(REGISTRATION_ELIGIBILITY_API),
  },
};
