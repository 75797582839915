import { AxiosError } from 'axios';

import URLS from 'jsx/constants/urls';
import AUTH_URLS from 'jsx/auth/constants/urls';
import HTTP_STATUS_CODES from 'jsx/constants/httpStatusCodes';

import { AxiosBuilderInterceptor } from './types';

/**
 * Get the path the user should be redirected to.
 */
const redirectTo = (): string => {
  const path = window.location.pathname;

  // If they were trying to access an admin route, send them to the admin login.
  if (path.startsWith('/admin') || path.startsWith('/pharmacy')) {
    return URLS.adminLogin;
  }

  // Send users that try to access the signup page when they aren't authenticated
  // to the landing page instead of the login page. They're likely following a link
  // someone sent them to signup and don't have an account yet, so sending them to
  // the login page just increases the likelihood that they bounce.
  if (path.startsWith('/signup')) {
    return URLS.landingPage;
  }

  // Otherwise we can send them straight to the login page to log back in.
  return AUTH_URLS.login;
};

/// auth/login/email
export const authErrorHandler = (): AxiosBuilderInterceptor => ({
  type: 'response',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorHandler: async (error: AxiosError<any>) => {
    const statusCode = error?.response?.status;

    // If we get an authn/authz error from the API, redirect the user so they
    // can login again (potentially as a different role).
    if (
      window.location.pathname !== AUTH_URLS.loginEmail &&
      (statusCode === HTTP_STATUS_CODES.unauthorized ||
        statusCode === HTTP_STATUS_CODES.forbidden)
    ) {
      window.location.href = redirectTo();

      return Promise.reject(error);
    }

    return Promise.reject(error);
  },
});
