import { AxiosInstance, AxiosRequestConfig } from 'axios';

import URLS from 'jsx/constants/urls';
import cookie from 'jsx/utils/cookie';

import { AxiosBuilderInterceptor } from './types';

const XSRF_TOKEN_COOKIE_NAME = 'XSRF-TOKEN';

const WRITE_HTTP_VERBS = ['put', 'post', 'delete', 'patch'];

/**
 * An interceptor that checks if we already have an XSRF token, and if not
 * requests one. This is necessary for the first request from a SPA because
 * the csrf token cannot be injected into the HTML.
 *
 * @see https://laravel.com/docs/8.x/csrf
 * @see https://laravel.com/docs/8.x/sanctum#spa-authentication
 * @see https://github.com/axios/axios#request-config
 */
export const csrfHandler = (axios: AxiosInstance): AxiosBuilderInterceptor => ({
  type: 'request',
  handler: async (request: AxiosRequestConfig) => {
    if (
      request.method &&
      WRITE_HTTP_VERBS.includes(request.method) &&
      !cookie.has(request.xsrfCookieName ?? XSRF_TOKEN_COOKIE_NAME)
    ) {
      // There's no risk of an infinite loop here because we only
      // require CSRF for write requests and this is a GET request.
      await axios.get(URLS.api.csrf());
    }

    return request;
  },
});
