import * as Sentry from '@sentry/react';
import { BrowserOptions } from '@sentry/react';

import CONFIG from 'jsx/constants/config';

export function cleanValues(obj: { id: number | string }) {
  const { id } = obj;

  return {
    id: String(id),
  };
}

const SENTRY_OPTIONS: BrowserOptions = {
  enabled: CONFIG.isProduction,
  dsn: CONFIG.keys.sentryDsn,
  tracesSampleRate: 0,
  release: CONFIG.commitId,
  environment: CONFIG.env,
  ignoreErrors: [/Network Error/i, /captured as promise rejection/i],
};

interface NotifyOptions {
  context?: string;
  metadata?: Record<string, any>;
  severity?: 'info' | 'warning' | 'error';
}

class ErrorReporter {
  constructor() {
    Sentry.init(SENTRY_OPTIONS);
  }

  /**
   * Use for error states
   */
  notifyException = (error: Error, options?: NotifyOptions): void => {
    Sentry.captureException(error, {
      extra: { ...options?.metadata },
      tags: { context: options?.context || 'no context' },
    });
  };

  /**
   * Use for messages/data we want to log as issues, but not necessarily error states
   */
  notify = (message: string, options?: NotifyOptions): void => {
    Sentry.captureMessage(message, {
      extra: { ...options?.metadata },
      level: options?.severity || 'warning',
      tags: { context: options?.context || 'no context' },
    });
  };

  setUser = (user: { id: number | string }) => {
    const { id } = cleanValues(user);

    Sentry.setUser({ id });
  };
}

const errorReporter = new ErrorReporter();

export default errorReporter;
